import { useTranslations } from "next-intl";
import Link from "next/link";
import React from "react";
import PaginationNextIcon from "../../../../assets/icons/PaginationNextIcon";
import BuilderCard from "../../../../common/builderCard/BuilderCard";
import { rootPaths } from "../../../../utils/constants/rootPaths";
import { viewTypes } from "../../../../utils/constants/viewTypes";
import { useGetTopBuilders } from "../../services/queries";
import styles from "./MainTopBuilders.module.scss";
const MainTopBuilders: React.FC = () => {
  const t = useTranslations("body.topBuilders");
  const {
    data
  } = useGetTopBuilders();
  return <section className={styles.container} data-sentry-component="MainTopBuilders" data-sentry-source-file="MainTopBuilders.tsx">
      <div className="wrapper">
        <div className={styles.container_header}>
          <h2>{t("Топовые застройщики")}</h2>
          <Link href={rootPaths.BUILDERS} legacyBehavior data-sentry-element="Link" data-sentry-source-file="MainTopBuilders.tsx">
            <a>
              {t("Показать все")} <PaginationNextIcon data-sentry-element="PaginationNextIcon" data-sentry-source-file="MainTopBuilders.tsx" />
            </a>
          </Link>
        </div>
        <div className={styles.container_body}>
          {data?.slice(0, 4)?.map((item, index) => <BuilderCard {...item} key={index} viewType={viewTypes.GRID} />)}
        </div>
      </div>
    </section>;
};
export default MainTopBuilders;