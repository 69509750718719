import { useTranslations } from "next-intl";
import Link from "next/link";
import React from "react";
import PaginationNextIcon from "../../../../assets/icons/PaginationNextIcon";
import MagazinesCont from "../../../../common/magazinesCont/MagazinesCont";
import { rootPaths } from "../../../../utils/constants/rootPaths";
import { useGetTopMagazines } from "../../services/queries";
import styles from "./MainMagazines.module.scss";
const MainMagazines: React.FC = () => {
  const t = useTranslations("body.magazines");
  const {
    data
  } = useGetTopMagazines();
  return <section className={styles.container} data-sentry-component="MainMagazines" data-sentry-source-file="MainMagazines.tsx">
      <div className="wrapper">
        <div className={styles.container_header}>
          <h2 className={styles.title}>{t("Журнал UYSOT")}</h2>
          <Link href={rootPaths.MAGAZINES} legacyBehavior data-sentry-element="Link" data-sentry-source-file="MainMagazines.tsx">
            <a>
              {t("Показать все")} <PaginationNextIcon data-sentry-element="PaginationNextIcon" data-sentry-source-file="MainMagazines.tsx" />
            </a>
          </Link>
        </div>
        <MagazinesCont magazines={data} data-sentry-element="MagazinesCont" data-sentry-source-file="MainMagazines.tsx" />
      </div>
    </section>;
};
export default MainMagazines;