import queryString from "query-string"

export const queryKeys = {
  SIMILAR_COMPLEXES: "similar_complexes",
  NEW_SIMILAR_COMPLEXES: "new_similar_complexes",
  NEW_SIMILAR_APARTMENTS: "new_similar_apartments",
  COMPLEXES_OF_BUILDER: "complexes_of_builder",
  NEW_COMPLEXES_OF_BUILDER: "new_complexes_of_builder",
  PAYMENT_METHODS_SELECT: "payment_methods_select",
  BANNER: (query?: { page_type?: string; uuid?: string; lang?: string }) =>
    `banner-${queryString.stringify(query || {})}`,
  BANNER_VIEW: (uuid?: string) => `banner-view-${uuid}`,
}
