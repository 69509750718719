import Image from "next/image";
import Link from "next/link";
import React from "react";
import { BuilderType } from "../../pages/builders/utils/models/builderModel";
import { imageLayouts, imageLoadings, imageObjectFits } from "../../utils/constants/imageAttributes";
import { rootPaths } from "../../utils/constants/rootPaths";
import { viewTypes } from "../../utils/constants/viewTypes";
import { makeUrl } from "../../utils/helpers/makeUrl";
import styles from "./builderCard.module.scss";
import { useRouter } from "next/router";
interface Props {
  viewType: viewTypes;
}
type BuilderCardType = BuilderType & Props;
const BuilderCard: React.FC<BuilderCardType> = props => {
  const {
    locale
  } = useRouter();
  const regionText = (props?.region && locale === "uz" ? props?.region?.name?.uz : props?.region?.name?.ru) || props?.region?.name?.uz;
  const districtText = (props?.district && locale === "uz" ? props?.district?.name?.uz : props?.district?.name?.ru) || props?.district?.name?.ru;
  return <div className={`${styles.builder_card} ${styles[props.viewType]}`} data-sentry-component="BuilderCard" data-sentry-source-file="BuilderCard.tsx">
      <div className={styles.builder_card_left}>
        <Link href={`${rootPaths.BUILDERS}/${makeUrl(props.name)}-${props.builder_id}`} legacyBehavior data-sentry-element="Link" data-sentry-source-file="BuilderCard.tsx">
          <a>
            <h3 className="!mb-0">{props.name}</h3>
          </a>
        </Link>
        {props.viewType === viewTypes.LIST && <p>
            {regionText ? regionText + ", " : ""}
            {districtText}
            {/* {props[`address_${locale}` as keyof typeof props]?.toString()} */}
          </p>}
      </div>
      <div className={styles.builder_card_right}>
        {props?.image && <Image src={props.image} layout={imageLayouts.FILL} objectFit={imageObjectFits.CONTAIN} loading={imageLoadings.LAZY} alt={props.name} />}
      </div>
    </div>;
};
export default BuilderCard;