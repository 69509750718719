import { dehydrate, QueryClient } from "@tanstack/react-query";
import { GetStaticPropsContext, NextPage } from "next";
import NextHead from "../src/common/next-head/NextHead";
import { fetchBanners, fetchPaymentMethods } from "../src/pages/app/services/queryFunctions";
import { queryKeys as appQueryKeys } from "../src/pages/app/utils/constants/queryKeys";
import { queryKeys as mainQueryKeys } from "../src/pages/main/utils/constants/queryKeys";
import Main from "../src/pages/main/Index";
import { fetchApartmentCounts, fetchCounts, fetchTopBuilders, fetchTopComplexes, fetchTopMagazines } from "../src/pages/main/services/queryFunctions";
import { queryKeys } from "../src/pages/main/utils/constants/queryKeys";
import { layoutLang } from "../src/utils/language/layout";
import { useRouter } from "next/router";
import { $newapi } from "services/RequestService";
const Index: NextPage = () => {
  const {
    locale
  } = useRouter();
  return <>
      <NextHead title={layoutLang[String(locale)]["title"]} ogTitle="Uysot" data-sentry-element="NextHead" data-sentry-source-file="index.tsx" />
      <Main data-sentry-element="Main" data-sentry-source-file="index.tsx" />
    </>;
};
export default Index;
export async function getStaticProps(context: GetStaticPropsContext) {
  const queryClient = new QueryClient();

  // counts
  await queryClient.prefetchQuery([queryKeys.COUNTS], fetchCounts);

  // apartment counts
  await queryClient.prefetchQuery([queryKeys.APARTMENT_COUNTS], fetchApartmentCounts);

  // top builders
  await queryClient.prefetchQuery([queryKeys.TOP_BUILDERS], fetchTopBuilders);

  // await queryClient.prefetchQuery(
  //   [appQueryKeys.BANNER({ page_type: "main", lang: context.locale })],
  //   () => fetchBanners({ page_type: "main", lang: context.locale })
  // )
  // top magazines
  await queryClient.prefetchQuery([queryKeys.NEW_TOP_MAGAZINES, context.locale], () => fetchTopMagazines(context.locale));

  // payment methods
  await queryClient.prefetchQuery([appQueryKeys.PAYMENT_METHODS_SELECT], fetchPaymentMethods);
  await queryClient.prefetchQuery([queryKeys.MAIN_REGIONS], async () => {
    const res = await $newapi.get(`/city/select`);
    return res.data.data;
  });
  const {
    url,
    fetch
  } = fetchTopComplexes();
  await queryClient.prefetchQuery([mainQueryKeys.TOP_COMPLEXES, url], fetch);
  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      messages: (await import(`../messages/main/${context.locale}.json`)).default
    },
    revalidate: 43200
  };
}