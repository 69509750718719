import { Checkbox, Dropdown, Input, InputRef, Tabs } from "antd";
import React, { useRef, useState } from "react";
import { useTranslations } from "next-intl";
import ArrowBottom from "assets/icons/ArrowBottom";
import { hotSearchs, initialRooms, maxInitPrice, maxPerPrice, maxPrice, minInitPrice, minPerPrice, minPrice } from "./mainFilterItems";
import { localeFormatter } from "utils/helpers/localeFormatter";
import { priceSeparator } from "utils/helpers/priceSeparator";
import { useRouter } from "next/router";
import { layoutLang } from "utils/language/layout";
import { rootPaths } from "utils/constants/rootPaths";
import SearchIcon from "assets/icons/SearchIcon";
import Link from "next/link";
import { useGetMainRegions } from "pages/main-layout/services/queries";
const MainFilter = () => {
  const t = useTranslations("priceChange");
  const {
    data
  } = useGetMainRegions();
  const {
    locale,
    push
  } = useRouter();
  const [roomsCount, setRoomsCount] = useState<string[]>([]);
  const [minValue, setMinValue] = useState<string>();
  const [maxValue, setMaxValue] = useState<string>();
  const [district, setDistrict] = useState<string>("gorod-tashkent");
  const [activeInput, setActiveInput] = useState<string>("input2");
  const [priceType, setPriceType] = useState({
    min: "",
    max: ""
  });
  const inputRef1 = useRef<InputRef>(null);
  const inputRef2 = useRef<InputRef>(null);
  const handleChangeRooms = (value: string) => {
    if (roomsCount?.includes(value)) {
      const count = roomsCount?.filter(item => item !== value);
      setRoomsCount(count);
    } else {
      setRoomsCount(state => [...state, value]);
    }
  };
  const regionId = data?.find(item => item?.url === district)?.id;
  const regionUrl = data?.find(item => item?.url === district)?.url;
  const changeType = (str: string) => {
    return Number(str?.replace(/\s/g, ""));
  };
  const handleSearch = () => {
    push({
      pathname: `/${regionUrl}${rootPaths.APARTMENTS}`,
      query: {
        rooms_count: roomsCount,
        region_id: regionId,
        [priceType?.min]: changeType(minValue!) ? changeType(minValue!) : [],
        [priceType?.max]: changeType(maxValue!) ? changeType(maxValue!) : []
      }
    });
  };
  const tabChildren = (price1: number[], price2: number[], type: string) => {
    return <div className="flex" data-sentry-component="tabChildren" data-sentry-source-file="MainFilter.tsx">
        <div>
          <Input prefix={t("от")} value={minValue ? localeFormatter(minValue!) : ""} onChange={e => {
          setMinValue(e.target.value);
          setPriceType(state => ({
            ...state,
            min: type?.replace("max", "min")
          }));
        }} maxLength={14} ref={inputRef1} onFocus={() => setActiveInput("input1")} data-sentry-element="Input" data-sentry-source-file="MainFilter.tsx" />
          <div className="flex flex-col mt-2 gap-1" style={{
          display: activeInput === "input1" ? "flex" : "none"
        }}>
            {price1?.map(item => <div className="hover:bg-[#daf3eb] rounded-[5px] transition-all duration-300 cursor-pointer px-2 py-1 text-[#1D2939] font-medium" onClick={() => {
            setMinValue(String(item));
            !maxValue && setActiveInput("input2");
            setPriceType(state => ({
              ...state,
              min: type?.replace("max", "min")
            }));
          }} style={{
            backgroundColor: +minValue! === item ? "#daf3eb" : ""
          }}>
                {item?.toLocaleString("ru")}
              </div>)}
          </div>
        </div>
        <div>
          <Input prefix={t("до")} value={maxValue ? localeFormatter(maxValue!) : ""} onChange={e => {
          setMaxValue(e.target.value);
          setPriceType(state => ({
            ...state,
            max: type?.replace("min", "max")
          }));
        }} maxLength={15} ref={inputRef2} onFocus={() => setActiveInput("input2")} data-sentry-element="Input" data-sentry-source-file="MainFilter.tsx" />
          <div className="flex flex-col mt-2 gap-1" style={{
          display: activeInput === "input2" ? "flex" : "none"
        }}>
            {price2?.map(item => <div className="hover:bg-[#daf3eb] rounded-[5px] transition-all duration-300 cursor-pointer px-2 py-1 text-[#1D2939] font-medium" onClick={() => {
            setMaxValue(String(item));
            !minValue && setActiveInput("input1");
            setPriceType(state => ({
              ...state,
              max: type?.replace("min", "max")
            }));
          }} style={{
            backgroundColor: +maxValue! === item ? "#daf3eb" : ""
          }}>
                {item?.toLocaleString("ru")}
              </div>)}
          </div>
        </div>
      </div>;
  };
  return <div className="md:flex hidden flex-col gap-2 w-full" data-sentry-component="MainFilter" data-sentry-source-file="MainFilter.tsx">
      <div className="flex items-center gap-5 h-[49px] w-full justify-center">
        <div className="flex items-center h-full bg-white rounded-xl">
          <div className="flex items-center flex-col px-4 w-[260px] border-r border-solid border-[#E9E9E9] h-full cursor-pointer relative">
            <Dropdown trigger={["click", "hover"]} mouseEnterDelay={1000} mouseLeaveDelay={0.3} overlay={<div className="flex flex-col bg-white w-full rounded-[4px]" style={{
            boxShadow: "0 4px 16px rgba(33,31,46,.12)"
          }}>
                  {initialRooms?.map(item => <Checkbox key={item?.key} value={item?.key} className="!m-0 px-2 py-1 rounded-[4px] hover:bg-[#daf3eb] transition-all duration-300" onChange={e => handleChangeRooms(e?.target?.value)}>
                      {item?.label}
                    </Checkbox>)}
                </div>} data-sentry-element="Dropdown" data-sentry-source-file="MainFilter.tsx">
              <div className="flex items-center justify-between w-full h-full">
                <span className="text-[#1D2939] font-medium">
                  {roomsCount?.length < 1 ? t("Xonalar_soni") : roomsCount?.sort((a, b) => Number(a) - Number(b))?.map(item => item === "0" ? t("Студия") : item)?.join(", ")}
                </span>
                <ArrowBottom data-sentry-element="ArrowBottom" data-sentry-source-file="MainFilter.tsx" />
              </div>
            </Dropdown>
          </div>
          <div className="flex items-center flex-col px-4 w-[280px] border-r border-solid border-[#E9E9E9] h-full cursor-pointer relative">
            <Dropdown trigger={["click", "hover"]} mouseEnterDelay={1000} mouseLeaveDelay={0.3} overlay={<div className="flex flex-col bg-white rounded-[4px] w-[400px] px-6 pb-4" style={{
            boxShadow: "0 4px 16px rgba(33,31,46,.12)"
          }}>
                  <Tabs items={[{
              key: "1",
              label: t("Цена"),
              children: tabChildren(minPrice, maxPrice, "min_total_price")
            }, {
              key: "2",
              label: t(`Цена`) + `(${t("м²")})`,
              children: tabChildren(minPerPrice, maxPerPrice, "price_min_per_area")
            }, {
              key: "3",
              label: t("Первоначальный платеж"),
              children: tabChildren(minInitPrice, maxInitPrice, "prepayment_max")
            }]} />
                </div>} data-sentry-element="Dropdown" data-sentry-source-file="MainFilter.tsx">
              <div className="flex items-center justify-between w-full h-full">
                <span className="text-[#1D2939] font-medium">
                  {!minValue && !maxValue ? layoutLang[String(locale)]["tolov_summasi"] : minValue && !maxValue ? `${locale === "uz" ? `${priceSeparator(changeType(minValue), t)} dan` : `${t("от")} ${priceSeparator(changeType(minValue), t)}`}` : maxValue && !minValue ? `${locale === "uz" ? `${priceSeparator(changeType(maxValue), t)} gacha` : `${t("до")} ${priceSeparator(changeType(maxValue), t)}`} ` : minValue && maxValue ? `${priceSeparator(changeType(minValue), t)} - ${priceSeparator(changeType(maxValue), t)}` : ""}
                </span>
                <ArrowBottom data-sentry-element="ArrowBottom" data-sentry-source-file="MainFilter.tsx" />
              </div>
            </Dropdown>
          </div>
          <div className="flex items-center flex-col px-4 w-[65%] h-full cursor-pointer relative">
            <Dropdown trigger={["click", "hover"]} mouseEnterDelay={1000} mouseLeaveDelay={0.3} overlay={<div className="flex flex-col bg-white rounded-[4px] p-2 h-[300px] overflow-auto gap-1" style={{
            boxShadow: "0 4px 16px rgba(33,31,46,.12)"
          }}>
                  {data?.map(item => <div className="hover:bg-[#daf3eb] rounded-[5px] transition-all duration-300 cursor-pointer px-2 py-1 text-[#1D2939] font-medium" key={item?.id} onClick={() => setDistrict(item?.url)} style={{
              backgroundColor: item?.url === district ? "#daf3eb" : ""
            }}>
                      {item?.name[String(locale) as keyof typeof item.name]}
                    </div>)}
                </div>} data-sentry-element="Dropdown" data-sentry-source-file="MainFilter.tsx">
              <div className="flex items-center justify-between w-full h-full">
                <span className="text-[#1D2939] font-medium">
                  {locale === "uz" ? `${t(`${district}`)} dan` : `из ${t(`${district}`)}`}
                </span>
                <ArrowBottom data-sentry-element="ArrowBottom" data-sentry-source-file="MainFilter.tsx" />
              </div>
            </Dropdown>
          </div>
        </div>
        <div className="flex items-center gap-2 h-full">
          {/* <div className="flex items-center bg-white h-full gap-2.5 rounded-xl px-4 cursor-pointer">
            <MapIcon />
            <p className="text-[#0DC683] font-medium">{t("xaritada_korish")}</p>
           </div> */}
          <div className="flex items-center h-full gap-2.5 rounded-xl px-4 cursor-pointer bg-[#0DC683]" onClick={handleSearch}>
            <SearchIcon size="16" color="#fff" data-sentry-element="SearchIcon" data-sentry-source-file="MainFilter.tsx" />
            <p className="text-white font-medium">{t("Izlash")}</p>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-1 justify-center flex-wrap">
        {hotSearchs?.map(item => <Link href={item?.link} className="rounded-2xl h-[33px] bg-[#E6E6E6] px-[15px] flex items-center text-[#2C2C2C] cursor-pointer select-none" key={item?.value?.uz}>
            {item?.value[locale as keyof typeof item.value]}
          </Link>)}
      </div>
    </div>;
};
export default MainFilter;