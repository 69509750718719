import { useLocale, useTranslations } from "next-intl";
import Image from "next/image";
import Link from "next/link";
import React, { useMemo } from "react";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import { imageLayouts, imageObjectFits } from "../../../../utils/constants/imageAttributes";
import { ROOMS_COUNT, DEADLINE } from "../../../../utils/constants/queryParamsKeys";
import { rootPaths } from "../../../../utils/constants/rootPaths";
import calculatorImg from "../../assets/images/useful-links-calculator.png";
import complexesImg from "../../assets/images/useful-links-complexes.png";
import { useGetApartmentCounts } from "../../services/queries";
import styles from "./MainUsefulLinks.module.scss";
import dayjs from "dayjs";
const MainUsefulLinks: React.FC = () => {
  const t = useTranslations("body.usefulLinks");
  const locale = useLocale();
  const {
    data: apartmentCounts
  } = useGetApartmentCounts();
  const {
    userAddress
  } = useAppSelector(state => state.userReducer);
  const deadlineReadyHouse = dayjs().add(-1, "year").endOf("year").format("YYYY-MM-DD");
  const deadlineEndYear = dayjs().endOf("year").format("YYYY-MM-DD");
  const deadlineNextYear = dayjs().add(1, "year").endOf("year").format("YYYY-MM-DD");
  const deadlineNextTwoYear = dayjs().add(2, "year").endOf("year").format("YYYY-MM-DD");

  // location
  const location = useMemo(() => userAddress?.url_name, [userAddress]);
  return <section className={styles.container} data-sentry-component="MainUsefulLinks" data-sentry-source-file="MainUsefulLinks.tsx">
      <div className="wrapper">
        <h2 className={styles.container_title}>{t("Полезные ссылки")}</h2>
        <div className={styles.container_body}>
          <div className={styles.apartments}>
            <h3 className={styles.title}>{t("Купить квартиру")}</h3>
            <div className={styles.body}>
              {apartmentCounts?.map((item, index) => <Link key={index} href={{
              pathname: `/${location}${rootPaths.APARTMENTS}`,
              query: {
                [ROOMS_COUNT]: item.rooms_count
              }
            }} legacyBehavior>
                  <a>
                    <p>
                      {item?.rooms_count}-{t("комнатная")}
                    </p>
                    <span>{item?.apartments_count}</span>
                  </a>
                </Link>)}
            </div>
          </div>
          <div className={styles.complexes}>
            <h3 className={styles.title}>{t("Новостройка")}</h3>
            <div className={styles.body}>
              <Link href={{
              pathname: `/${location}${rootPaths.RESIDENTIAL_COMPLEXES}`,
              query: {
                [DEADLINE]: deadlineReadyHouse
              }
            }} legacyBehavior data-sentry-element="Link" data-sentry-source-file="MainUsefulLinks.tsx">
                <a>{t("Готовые ЖК")}</a>
              </Link>
              <Link href={{
              pathname: `/${location}${rootPaths.RESIDENTIAL_COMPLEXES}`,
              query: {
                [DEADLINE]: deadlineEndYear
              }
            }} legacyBehavior data-sentry-element="Link" data-sentry-source-file="MainUsefulLinks.tsx">
                <a>{t("Сдача до конца года")}</a>
              </Link>
              <Link href={{
              pathname: `/${location}${rootPaths.RESIDENTIAL_COMPLEXES}`,
              query: {
                [DEADLINE]: deadlineNextYear
              }
            }} legacyBehavior data-sentry-element="Link" data-sentry-source-file="MainUsefulLinks.tsx">
                <a>
                  {locale === "ru" ? t("Сдача до 2023") + dayjs().add(1, "year").format("YYYY") : dayjs().add(1, "year").format("YYYY") + t("Сдача до 2023")}
                </a>
              </Link>
              <Link href={{
              pathname: `/${location}${rootPaths.RESIDENTIAL_COMPLEXES}`,
              query: {
                [DEADLINE]: deadlineNextTwoYear
              }
            }} legacyBehavior data-sentry-element="Link" data-sentry-source-file="MainUsefulLinks.tsx">
                <a>
                  {locale === "ru" ? t("Сдача до 2024") + dayjs().add(2, "year").format("YYYY") : dayjs().add(2, "year").format("YYYY") + t("Сдача до 2024")}
                </a>
              </Link>
            </div>
            <div className={styles.image}>
              <Image src={complexesImg} alt="usefull-link" layout={imageLayouts.RESPONSIVE} objectFit={imageObjectFits.CONTAIN}
            // width="100%"
            // height="100%"
            data-sentry-element="Image" data-sentry-source-file="MainUsefulLinks.tsx" />
            </div>
          </div>
          <div className={styles.calculator}>
            <div className={styles.left}>
              <h3 className={styles.title}>{t("Калькулятор ипотеки")}</h3>
              <p>
                {t("Рассчитайте платежи")} <br /> {t("и отправьте заявку")}
              </p>
              <Link href={rootPaths.MORTGAGE_CALCULATOR} legacyBehavior data-sentry-element="Link" data-sentry-source-file="MainUsefulLinks.tsx">
                <a>{t("Рассчитать ипотеку")}</a>
              </Link>
            </div>
            <div className={styles.right}>
              <Image src={calculatorImg} alt="calculator-img" layout={imageLayouts.RESPONSIVE} objectFit={imageObjectFits.CONTAIN}
            // width="100%"
            // height="100%"
            data-sentry-element="Image" data-sentry-source-file="MainUsefulLinks.tsx" />
            </div>
          </div>
        </div>
      </div>
    </section>;
};
export default MainUsefulLinks;