import React from "react";
type Search = {
  className?: string;
  onClick?: () => void;
  size?: string;
  color?: string;
};
const SearchIcon: React.FC<Search> = ({
  className,
  onClick,
  size,
  color
}) => {
  return <svg width={size} height={size} viewBox="0 0 16 16" fill="none" className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="SearchIcon" data-sentry-source-file="SearchIcon.tsx">
      <path d="M7.5 13C10.5376 13 13 10.5376 13 7.5C13 4.46243 10.5376 2 7.5 2C4.46243 2 2 4.46243 2 7.5C2 10.5376 4.46243 13 7.5 13Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="SearchIcon.tsx" />
      <path d="M14 14L11.5 11.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="SearchIcon.tsx" />
    </svg>;
};
export default SearchIcon;