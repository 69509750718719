import React from "react";
import MainMagazines from "./components/magazines/MainMagazines";
import MainNewComplexes from "./components/new-complexes/NewComplexes";
import MainTopBuilders from "./components/top-builders/MainTopBuilders";
import MainTop from "./components/top/MainTop";
import MainUsefulLinks from "./components/useful-links/MainUsefulLinks";
import SeoText from "./components/seo-text/SeoText";
const Index: React.FC = () => {
  return <>
      <MainTop data-sentry-element="MainTop" data-sentry-source-file="Index.tsx" />
      <MainNewComplexes data-sentry-element="MainNewComplexes" data-sentry-source-file="Index.tsx" />
      <MainUsefulLinks data-sentry-element="MainUsefulLinks" data-sentry-source-file="Index.tsx" />
      <MainTopBuilders data-sentry-element="MainTopBuilders" data-sentry-source-file="Index.tsx" />
      <MainMagazines data-sentry-element="MainMagazines" data-sentry-source-file="Index.tsx" />
      <SeoText data-sentry-element="SeoText" data-sentry-source-file="Index.tsx" />
    </>;
};
export default Index;