export const minPrice = [
  200000000, 300000000, 400000000, 500000000, 600000000, 700000000,
]
export const maxPrice = [
  300000000, 400000000, 500000000, 600000000, 700000000, 800000000,
]
export const minPerPrice = [
  2000000, 2500000, 3000000, 3500000, 4000000, 4500000,
]
export const maxPerPrice = [
  3000000, 3500000, 4000000, 4500000, 5000000, 8000000, 12000000, 15000000,
]
export const minInitPrice = [
  100000000, 120000000, 160000000, 200000000, 240000000, 280000000,
]
export const maxInitPrice = [
  120000000, 160000000, 200000000, 240000000, 280000000, 360000000,
]
export const hotSearchs = [
  {
    link: "/gorod-tashkent/kvartiri?rooms_count=2",
    value: { uz: "2 xonali kvartiralar", ru: "2-комнатные квартиры" },
  },
  {
    link: "/gorod-tashkent/kvartiri?deadline=31-12-2024",
    value: {
      uz: "2024 bitadiganlar",
      ru: "Строящиеся новостройки",
    },
  },
  {
    link: "/gorod-tashkent/kvartiri?region_id=12",
    value: { uz: "Toshkent markazidan", ru: "из центра Ташкента" },
  },
  {
    link: "/gorod-tashkent/kvartiri?class_id=2",
    value: { uz: "Biznes klass", ru: "Бизнес класс" },
  },
  {
    link: "/gorod-tashkent/kvartiri?deadline=31-12-2023",
    value: { uz: "Tayyor uylar", ru: "Сданные новостройки" },
  },
]
export const initialRooms = [
  {
    label: "Студия",
    key: "0",
  },
  {
    label: "1",
    key: "1",
  },
  {
    label: "2",
    key: "2",
  },
  {
    label: "3",
    key: "3",
  },
  {
    label: "4+",
    key: "4",
  },
]
