import { Grid } from "antd";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import ArrowDownIcon from "../../../../assets/icons/ArrowDownIcon";
import LocationIcon from "../../../../assets/icons/LocationIcon";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import { rootPaths } from "../../../../utils/constants/rootPaths";
import { useGetCounts } from "../../services/queries";
import style from "./MainTop.module.scss";
import { useBanners } from "../../../app/services/queries";
import ViewAdv from "../../../../common/view-adv/ViewAdv";
import Image from "next/image";
import MainFilter from "../main-filter/MainFilter";
const MainTop: React.FC = () => {
  const t = useTranslations("body.mainTop");
  const router = useRouter();
  const {
    data: counts
  } = useGetCounts();
  const {
    data: banners,
    isLoading
  } = useBanners("main", router.locale);
  const {
    md
  } = Grid.useBreakpoint();
  const {
    userAddress
  } = useAppSelector(state => state.userReducer);

  // location
  const location = useMemo(() => userAddress?.url_name, [userAddress]);
  return <section className={style.main_top} style={{
    backgroundImage: isLoading || banners?.top?.images?.web?.url ? "none" : ""
  }} data-sentry-component="MainTop" data-sentry-source-file="MainTop.tsx">
      <div className="wrapper d_f fd_c ai_c p_r" style={{
      backgroundImage: isLoading || banners?.top?.images?.web?.url ? "none" : ""
    }}>
        {md && banners?.top?.images?.web?.url ? <ViewAdv uuid={banners?.top?.uuid}>
            <a className={style.reklama} target={banners?.top?.blank ? "_blank" : "_self"} href={`https://services.marketplace.uysot.uz/api/banner/view?uuid=${banners?.top?.uuid}&lang=${router.locale}`}>
              <div className={style.reklama_tag}>
                <span>{t("Реклама")}</span>
              </div>
              <Image src={banners?.top?.images?.web?.url || ""} fill alt="" />
            </a>
          </ViewAdv> : <></>}

        <div className={`${style.main_top_top} w-full`} style={{
        backgroundImage: isLoading || banners?.top?.images?.web?.url ? "none" : ""
      }}>
          {!md && (banners?.top?.images?.web?.url || banners?.top?.images?.mobile?.url) ? <ViewAdv uuid={banners?.top?.uuid}>
              <a className={style.reklama} target={banners?.top?.blank ? "_blank" : "_self"} href={`https://services.marketplace.uysot.uz/api/banner/view?uuid=${banners?.top?.uuid}&lang=${router.locale}`}>
                <div className={style.reklama_tag}>
                  <span>{t("Реклама")}</span>
                </div>
                <img src={banners?.top?.images?.mobile?.url || banners?.top?.images?.web?.url || ""} alt="" />
              </a>
            </ViewAdv> : <></>}
          <div className="flex flex-col justify-center w-full">
            <h1>{t("Найди дом своей мечты прямо сейчас")}</h1>
            <MainFilter data-sentry-element="MainFilter" data-sentry-source-file="MainTop.tsx" />
          </div>
        </div>
        <div className={style.main_top_bottom}>
          <div className={style.main_top_bottom_body}>
            <Link href={`/${location}${rootPaths.RESIDENTIAL_COMPLEXES}`} legacyBehavior data-sentry-element="Link" data-sentry-source-file="MainTop.tsx">
              <a className={style.item}>
                <div className={style.top}>
                  <span>{t("Каталог ЖК")}</span>
                  <ArrowDownIcon data-sentry-element="ArrowDownIcon" data-sentry-source-file="MainTop.tsx" />
                </div>
                <div className={style.bottom}>
                  <span>{t("Всего объявлений")}</span>
                  <p>{counts?.complexes_count}</p>
                </div>
              </a>
            </Link>
            <Link href={`/${location}${rootPaths.APARTMENTS}`} legacyBehavior data-sentry-element="Link" data-sentry-source-file="MainTop.tsx">
              <a className={style.item}>
                <div className={style.top}>
                  <span>{t("Квартиры")}</span>
                  <ArrowDownIcon data-sentry-element="ArrowDownIcon" data-sentry-source-file="MainTop.tsx" />
                </div>
                <div className={style.bottom}>
                  <span>{t("Всего объявлений")}</span>
                  <p>{counts?.apartments_count}</p>
                </div>
              </a>
            </Link>

            <Link href={rootPaths.MAP} legacyBehavior data-sentry-element="Link" data-sentry-source-file="MainTop.tsx">
              <a className={`${style.item} ${style.map}`} aria-hidden>
                <LocationIcon data-sentry-element="LocationIcon" data-sentry-source-file="MainTop.tsx" />
                <span>
                  {t("Показать на")}
                  <br />
                  {t("карте")}
                </span>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </section>;
};
export default MainTop;